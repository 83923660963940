import React from 'react';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import SocialForm, { SocialFormSchema } from '@src/components/form/SocialForm';
import SocialShare from '../form/SocialShare';
import { ROUTES } from '@src/types/routes';
import classnames from 'classnames';
import ButtonSwiper from '../ButtonSwiper';
import { useSpring, animated } from 'react-spring';
import { useRecoilState } from 'recoil';
import { downloadDialogueGlobalState, modalGlobalState } from '@src/recoil';
import { useWebShareApi } from '@src/hooks/useWebShareApi';

const backOnlineCopy =
  "I've just had some quality time in Real Mode but I'm back online and ready to social up a storm. Hit me up!";

export default function ShareOnSocial({ closeModal, isGoingOnline }) {
  const router = useRouter();

  const { supportsWebShareApi } = useWebShareApi();

  const [modalState, setModalState] = useRecoilState(modalGlobalState);

  const [downloadDialogueState, setDownloadDialogueGlobalState] =
    useRecoilState(downloadDialogueGlobalState);

  // aninmate download dialogue mask opacity
  const dialogueMaskAnimationStyles = useSpring({
    opacity: downloadDialogueState.open ? 0.8 : 0
  });

  const dialogueMaskAnimationClassName = classnames('dialogue-mask white', {
    visible: downloadDialogueState.open
  });

  const {
    register,
    getValues,
    watch,
    setValue,
    trigger,
    formState: { errors, isSubmitting, isValid }
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(SocialFormSchema),
    ...(isGoingOnline && {
      defaultValues: {
        postMessage: backOnlineCopy
      }
    })
  });

  const allDone = () => {
    router.replace({
      pathname: ROUTES.WELCOME
    });
    closeModal();
  };

  return (
    <>
      <Container className="d-flex flex-column px-0">
        <div className="px-4 mh-100 overflow-x-hidden">
          <Row>
            <Col>
              <div
                className={classnames('banner-image', {
                  online: !!isGoingOnline,
                  offline: !isGoingOnline
                })}
              ></div>
            </Col>
          </Row>

          <Row className="d-flex mt-auto">
            <Col>
              <SocialForm
                register={register}
                getValues={getValues}
                setValue={setValue}
                errors={errors}
                isSubmitting={isSubmitting}
                isGoingOnline={isGoingOnline}
              />
            </Col>
          </Row>
        </div>
        <div className="px-4 mt-auto">
          <div className="separator thick mt-0">
            <hr className="bg-primary" />
          </div>
          <Row>
            <Col>
              <div className="d-grid gap-3 mb-4">
                <SocialShare
                  // use watch to ensure we get latest value
                  postMessage={watch('postMessage')}
                  isValid={isValid}
                  trigger={trigger}
                  isGoingOnline={isGoingOnline}
                />
                <div className="form-info-box">
                  <h6>Favourite Social not here?</h6>
                  <p>
                    Download the images here and share with any Social network
                  </p>

                  <a
                    href="#"
                    onClick={(event) => {
                      event.preventDefault();
                      setDownloadDialogueGlobalState({
                        ...downloadDialogueState,
                        open: true
                      });
                    }}
                  >
                    {supportsWebShareApi ? 'Share images' : 'Download images'}
                  </a>
                </div>

                {isGoingOnline ? (
                  <Button variant="primary" size="lg" onClick={allDone}>
                    All done
                  </Button>
                ) : (
                  <ButtonSwiper
                    completeCallback={async () => {
                      setModalState({
                        modalContent: modalState.modalContent,
                        open: false
                      });
                      router.replace({
                        pathname: ROUTES.PRE_ACTIVE
                      });
                    }}
                    testId="switchToPreActiveButton"
                  >
                    Start Real Mode
                  </ButtonSwiper>
                )}
              </div>
              {/* hack for iOS Safari bottom tab bar */}
              <div className="mb-8" />
            </Col>
          </Row>
        </div>
      </Container>
      <animated.div
        style={dialogueMaskAnimationStyles}
        className={dialogueMaskAnimationClassName}
      />
    </>
  );
}
