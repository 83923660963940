import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { useSpring, animated } from 'react-spring';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { downloadDialogueGlobalState } from '@src/recoil';
import { useRecoilState } from 'recoil';
import CloseIcon from '../svg/CloseIcon';
import { useWebShareApi } from '@src/hooks/useWebShareApi';

export default function DownloadDialoguePortal({ showDialogue }) {
  const [downloadDialogueState, setDownloadDialogueGlobalState] =
    useRecoilState(downloadDialogueGlobalState);

  const [shouldRender, setShouldRender] = useState(false);
  const { supportsWebShareApi, shareImage, downloadImage } = useWebShareApi();

  // aninmate dialogue (slide up/down)
  const downloadDialogueAnimationStyles = useSpring({
    bottom: showDialogue ? 0 : -400,
    onStart: () => {
      if (showDialogue) {
        setShouldRender(true);
      }
    },

    onRest: () => {
      if (!showDialogue) {
        setShouldRender(false);
      }
    }
  });

  // dismissing dialogue resets dialogue state
  const dismissDialogue = (event: React.MouseEvent | React.TouchEvent) => {
    event.preventDefault();
    setDownloadDialogueGlobalState({
      ...downloadDialogueState,
      open: false
    });
  };

  // share image on social (if Web Share API is supported)
  const shareImageToSocial = async (
    event: React.MouseEvent | React.TouchEvent,
    image: string,
    name: string
  ) => {
    event.preventDefault();

    if (supportsWebShareApi) {
      await shareImage(image, name);
    }
  };

  if (typeof window !== 'undefined' && shouldRender) {
    return ReactDOM.createPortal(
      <Container className="dialogue-container p-0">
        <animated.div
          className="dialogue-container-inner bg-white px-4 text-start"
          style={downloadDialogueAnimationStyles}
        >
          <div className="d-grid gap-3 mb-4 mt-auto">
            <a href="#" className="px-0" onClick={dismissDialogue}>
              <CloseIcon />
            </a>
            <p className="my-2">
              Want to share #realmode on other Socials? Grab our images below.
              {!supportsWebShareApi &&
                ' Just long press on the image you want to save'}
            </p>
            <Row>
              <Col className="text-center">
                <h6>Heading offline</h6>
                <img
                  src="/images/back-in-2-dnd.png"
                  width="100%"
                  alt="Heading offline"
                  className="mb-2"
                  // if Web Share API is not supported then we fall back to image download
                  // PWA on iOS is buggy hence this strange approach
                  onClick={() => {
                    if (!supportsWebShareApi) {
                      downloadImage(
                        '/images/back-in-2-dnd.png',
                        'heading-offline'
                      );
                    }
                  }}
                />
                {supportsWebShareApi && (
                  <a
                    href="#"
                    onClick={(event) =>
                      shareImageToSocial(
                        event,
                        'back-in-2-dnd.png',
                        'heading-offline'
                      )
                    }
                  >
                    Share
                  </a>
                )}
              </Col>
              <Col className="text-center">
                <h6>Back online</h6>

                <img
                  src="/images/im-back.png"
                  width="100%"
                  alt="Back online"
                  className="mb-2"
                  // if Web Share API is not supported then we fall back to image download
                  // PWA on iOS is buggy hence this strange approach
                  onClick={() => {
                    if (!supportsWebShareApi) {
                      downloadImage(
                        '/images/back-in-2-dnd.png',
                        'heading-offline'
                      );
                    }
                  }}
                />
                {supportsWebShareApi && (
                  <a
                    href="#"
                    onClick={(event) =>
                      shareImageToSocial(event, 'im-back.png', 'back-online')
                    }
                  >
                    Share
                  </a>
                )}
              </Col>
            </Row>
          </div>
        </animated.div>
      </Container>,
      document.getElementById('download-dialogue-portal')
    );
  }
  return null;
}
