import React, { useState } from 'react';
import Badge from 'react-bootstrap/Badge';
import Emoji from '../Emoji';

interface Captions {
  onChange: (caption: string) => void;
}

const captions = [
  {
    id: 'bea',
    label: 'Bea',
    symbol: '🏝'
  },
  {
    id: 'nan',
    label: 'Nan',
    symbol: '👵'
  },
  {
    id: 'mum',
    label: 'Mum',
    symbol: '👩'
  },
  {
    id: 'dad',
    label: 'Dad',
    symbol: '👨'
  },
  {
    id: 'dinner',
    label: 'Dinner',
    symbol: '🍽'
  },
  {
    id: 'movie',
    label: 'Movie',
    symbol: '🍿'
  },
  {
    id: 'manicure',
    label: 'Manicure',
    symbol: '💅'
  },
  {
    id: 'love',
    label: 'Love',
    symbol: '😘'
  }
];

export default function Captions({ onChange }: Captions) {
  const [mutableCaptions, setMutableCaptions] = useState(captions);

  // update checkobx checked state
  const handleCaptionClick = (event: React.MouseEvent) => {
    const caption = mutableCaptions.find(
      (item) => item.id === event.currentTarget.getAttribute('data-caption-id')
    );

    setMutableCaptions([...mutableCaptions]);
    onChange(`${caption.symbol}`);
  };

  return (
    <div data-testid="captionSection">
      <p>
        <strong>Add to your caption</strong>
      </p>
      <div className="d-flex flex-wrap">
        {mutableCaptions.map(({ id, label, symbol }) => {
          return (
            <span key={id} className="p-0 me-1">
              <h2 className="d-inline-flex">
                <Badge
                  pill
                  bg="primary-outline"
                  data-caption-id={id}
                  onClick={handleCaptionClick}
                >
                  <Emoji label={label} symbol={symbol} />
                </Badge>
              </h2>
            </span>
          );
        })}
      </div>
    </div>
  );
}
