import React, { Component, ErrorInfo, ReactNode } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Logo from './svg/Logo';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false
  };

  public static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <Container className="pt-6">
          <Row className="my-auto py-4 text-white pt-6">
            <Col className="d-flex justify-content-center align-items-center flex-column">
              <Logo width="45vw" />
            </Col>
          </Row>
          <Row>
            <Col>
              <h2 className="text-white text-center pt-6">
                Sorry... there was an error
              </h2>
            </Col>
          </Row>
        </Container>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
