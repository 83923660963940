import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { useSpring, animated } from 'react-spring';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { tipsDialogueGlobalState } from '@src/recoil';
import { useRecoilState } from 'recoil';
import ButtonSwiper from '../ButtonSwiper';
import { GA_EVENTS, trackEvent } from '@src/services/gtag';
import { ROUTES } from '@src/types/routes';
import { useRouter } from 'next/router';
import { FirebaseService } from '@src/services/firebase';

export default function TipsDialoguePortal({ showDialogue }) {
  const [tipsDialogueState, setTipsDialogueState] = useRecoilState(
    tipsDialogueGlobalState
  );
  const [shouldRender, setShouldRender] = useState(false);
  const router = useRouter();

  // aninmate dialogue (slide up/down)
  const tipsDialogueAnimationStyles = useSpring({
    bottom: showDialogue ? 0 : -460,
    onStart: () => {
      if (showDialogue) {
        setShouldRender(true);
      }
    },

    onRest: () => {
      if (!showDialogue) {
        setShouldRender(false);
      }
    }
  });

  // dismissing dialogue resets dialogue state
  const dismissDialogue = () => {
    setTipsDialogueState({
      ...tipsDialogueState,
      open: false
    });
    FirebaseService.activateLifeMode();
    router.replace({
      pathname: ROUTES.ACTIVE
    });
    trackEvent(GA_EVENTS.SWITCH_TO_REAL_MODE);
  };

  if (typeof window !== 'undefined' && shouldRender) {
    return ReactDOM.createPortal(
      <Container className="dialogue-container p-0">
        <animated.div
          className="dialogue-container-inner bg-white px-4"
          style={tipsDialogueAnimationStyles}
        >
          <div className="d-grid gap-2 mb-4 mt-auto">
            <h1 className="my-2 text-center">Top Tips</h1>
            <Row className="justify-content-start align-items-center">
              <Col xs={3} className="d-flex justify-content-start">
                <img
                  src="/images/shushing-face.png"
                  width="64"
                  height="64"
                  alt="Shushing face"
                  className="mb-2 pull-left"
                />
              </Col>
              <Col className="d-flex justify-content-start">
                Switch to silent
              </Col>
            </Row>
            <Row className="justify-content-start align-items-center">
              <Col xs={3} className="d-flex justify-content-start">
                <img
                  src="/images/snoozing-face.png"
                  width="64"
                  height="64"
                  alt="Snoozing face"
                  className="mb-2 pull-left"
                />
              </Col>
              <Col className="d-flex justify-content-start">
                Pop your phone straight into sleep mode
              </Col>
            </Row>
            <Row className="d-flex justify-content-start align-items-center">
              <Col xs={3} className="d-flex justify-content-start">
                <img
                  src="/images/smug-face.png"
                  width="64"
                  height="64"
                  alt="Smug face"
                  className="mb-2 pull-left"
                />
              </Col>
              <Col className="d-flex justify-content-start">
                Go enjoy some Real Mode time
              </Col>
            </Row>

            <Row>
              <Col>
                <ButtonSwiper
                  completeCallback={dismissDialogue}
                  testId="switchToActiveButton"
                >
                  Start Real Mode
                </ButtonSwiper>
              </Col>
            </Row>
          </div>
        </animated.div>
      </Container>,
      document.getElementById('tips-dialogue-portal')
    );
  }
  return null;
}
