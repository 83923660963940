import { FirebaseApp, initializeApp } from 'firebase/app';
import {
  getFirestore,
  getDocs,
  doc,
  query,
  collection,
  Firestore,
  setDoc,
  Timestamp,
  DocumentReference,
  Query
} from 'firebase/firestore';
import {
  getAuth,
  signInAnonymously as _signInAnonymously,
  Auth
} from 'firebase/auth';

export interface VideoDocument {
  id: string;
  imgUrl: string;
}

export class FirebaseService {
  private static instance: FirebaseService;
  private _app: FirebaseApp;
  private _auth: Auth;
  private _firestoreDB: Firestore;

  private constructor() {
    const app = initializeApp({
      apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
      authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
      storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID
    });
    this._app = app;
    this._auth = getAuth(this._app);
    this._firestoreDB = getFirestore(this._app);
  }

  public static getUserRef(uid: string): DocumentReference {
    if (typeof window === 'undefined') {
      return null;
    }
    const instance = FirebaseService.getInstance();
    if (!uid) {
      return null;
    }
    return doc(instance._firestoreDB, 'users', uid);
  }

  public static getVideosQuery(isGoingOnline: boolean): Query<VideoDocument> {
    if (typeof window === 'undefined') {
      return null;
    }
    const { _firestoreDB } = FirebaseService.getInstance();
    const videosRef = collection(
      _firestoreDB,
      isGoingOnline ? 'videosOnline' : 'videosOffline'
    );
    return query(videosRef) as Query<VideoDocument>;
  }

  public static getInstance(): FirebaseService {
    if (typeof window === 'undefined') {
      throw new Error('this project can only use firebase on the client side');
    }
    if (!FirebaseService.instance) {
      FirebaseService.instance = new FirebaseService();
      const { _auth } = FirebaseService.getInstance();
      if (!_auth.currentUser) {
        _signInAnonymously(_auth);
      }
    }
    return FirebaseService.instance;
  }

  public static async updateUserDetails({
    name,
    address,
    phone
  }: {
    name: string;
    address: string;
    phone: string;
  }) {
    const { _firestoreDB, _auth } = FirebaseService.getInstance();
    const docRef = doc(_firestoreDB, 'users', _auth?.currentUser?.uid);

    await setDoc(docRef, { name, address, phone }, { merge: true });
  }

  public static async activateLifeMode() {
    const { _firestoreDB, _auth } = FirebaseService.getInstance();
    const docRef = doc(_firestoreDB, 'users', _auth?.currentUser?.uid);

    await setDoc(
      docRef,
      // use milliseconds
      { lifeModeStartTime: Timestamp.now().toMillis(), lifeModeActive: true },
      { merge: true }
    );
  }

  public static async deactivateLifeMode() {
    const { _firestoreDB, _auth } = FirebaseService.getInstance();
    const docRef = doc(_firestoreDB, 'users', _auth?.currentUser?.uid);

    await setDoc(
      docRef,
      // use milliseconds
      { lifeModeEndTime: Timestamp.now().toMillis(), lifeModeActive: false },
      { merge: true }
    );
  }

  public static async getVideos(
    isGoingOnline = false
  ): Promise<VideoDocument[]> {
    const { _firestoreDB } = FirebaseService.getInstance();
    const videosRef = collection(
      _firestoreDB,
      isGoingOnline ? 'videosOnline' : 'videosOffline'
    );
    const q = query(videosRef);
    const videos = await getDocs(q);
    return videos?.docs?.map((doc) => doc.data() as VideoDocument);
  }

  public get firestoreDB() {
    return this._firestoreDB;
  }

  public get auth() {
    return this._auth;
  }
}
