import React from 'react';

export default function TwitterIcon() {
  return (
    <svg fill="none" viewBox="0 0 48 48" width="48" height="48">
      <path
        fill="#fff"
        d="M37.44 15.665c-1.007.447-2.075.74-3.168.87a5.534 5.534 0 002.428-3.056 11.03 11.03 0 01-3.507 1.339 5.517 5.517 0 00-9.537 3.772c.001.424.049.846.14 1.26a15.677 15.677 0 01-11.373-5.732 5.518 5.518 0 001.695 7.34 5.558 5.558 0 01-2.502-.688v.073a5.512 5.512 0 004.427 5.404 5.646 5.646 0 01-2.49.096 5.511 5.511 0 005.15 3.83 11.057 11.057 0 01-6.844 2.348c-.44 0-.88-.027-1.316-.079a15.534 15.534 0 008.47 2.48c10.165 0 15.694-8.403 15.694-15.688v-.717c1.074-.78 2-1.746 2.733-2.852z"
      ></path>
    </svg>
  );
}
