import { isDesktop, isPlatformiOS, isPwaInstalled } from '@src/utils';
import { useEffect, useState } from 'react';

// we want an explicit state for when we are still in server side land, so the UI doesn't treat that state as "not desktop, not PWA etc" but as its own unique state.
// this is to protect against rehydration bugs
const UNINITIALIZED = 'UNINITIALIZED';
type Uninitialized = 'UNINITIALIZED';

/**
 * custom hook for safe usage of window based checks
 * The UI should then base any related conditional logic on explicit checks like isPlatformiOS === true / isPlatformiOS === false, to ensure the rendered UI is consistent
 * between first client side rendering (rehydration) and SSG rendering
 * See: https://www.joshwcomeau.com/react/the-perils-of-rehydration/
 */
export default function useWindowUtils() {
  const [_isPlatformiOS, setIsPlatformiOS] = useState<boolean | Uninitialized>(
    UNINITIALIZED
  );
  const [_isPwaInstalled, setIsPwaInstalled] = useState<
    boolean | Uninitialized
  >(UNINITIALIZED);
  const [_isDesktop, setIsDesktop] = useState<boolean | Uninitialized>(
    UNINITIALIZED
  );

  useEffect(() => {
    setIsPlatformiOS(isPlatformiOS());
    setIsPwaInstalled(isPwaInstalled());
    setIsDesktop(isDesktop());
  }, []);

  return {
    isPlatformiOS: _isPlatformiOS,
    isPwaInstalled: _isPwaInstalled,
    isDesktop: _isDesktop,
    isPlatformAndroid:
      _isPlatformiOS === UNINITIALIZED ? UNINITIALIZED : !_isPlatformiOS // good enough for us
  };
}
