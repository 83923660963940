import React from 'react';

export default function WhatsappIcon() {
  return (
    <svg fill="none" viewBox="0 0 49 48" width="48" height="48">
      <path
        fill="#fff"
        d="M11.227 37.502l1.897-6.935a13.39 13.39 0 115.201 5.077l-7.098 1.858zm7.425-4.28l.407.237a11.052 11.052 0 005.647 1.553 11.12 11.12 0 10-9.425-5.213l.266.418-1.13 4.106 4.236-1.101z"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M31.465 27.072c-.084-.141-.305-.226-.638-.39-.333-.163-1.982-.977-2.287-1.09-.305-.112-.53-.169-.75.17-.221.339-.865 1.084-1.062 1.31-.198.226-.39.248-.723.085a9.111 9.111 0 01-2.688-1.66 10.236 10.236 0 01-1.864-2.316.486.486 0 01.13-.672c.153-.152.333-.39.503-.564.135-.173.247-.363.333-.565a.622.622 0 000-.565c-.085-.164-.751-1.813-1.028-2.479-.277-.666-.565-.565-.757-.565h-.638a1.208 1.208 0 00-.892.418 3.766 3.766 0 00-1.169 2.79c0 1.649 1.197 3.236 1.361 3.462.164.225 2.36 3.597 5.715 5.048.627.265 1.26.502 1.909.706a4.663 4.663 0 002.106.13c.644-.096 1.976-.808 2.259-1.587.23-.523.293-1.105.18-1.666z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
