import React from 'react';
import * as yup from 'yup';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Captions from './Captions';
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue
} from 'react-hook-form';

export const SocialFormSchema = yup.object().shape({
  postMessage: yup
    .string()
    .min(10, 'Please use a minimum of 10 characters')
    .required('Please enter a message')
});

interface SocialForm {
  register: UseFormRegister<{
    postMessage: string;
  }>;
  getValues: UseFormGetValues<{
    postMessage: string;
  }>;
  setValue: UseFormSetValue<{
    postMessage: string;
  }>;
  // TODO: fix any
  errors: any;
  //errors:  FormState<{
  //   postMessage: string;
  // }>;
  isSubmitting: boolean;
  isGoingOnline?: boolean;
}

const infoBoxCopy =
  'Let your mates know who you’re heading into Real Mode with or how long you might be away, then hit the Social sites you want your message to appear on.';

export default function SocialForm({
  register,
  getValues,
  setValue,
  errors,
  isSubmitting,
  isGoingOnline = false
}: SocialForm) {
  // add / remove captions to / from postMessage
  const updatePostMessageCaption = (caption: string) => {
    const currentPostMessage = getValues('postMessage');

    const newPostMessage =
      currentPostMessage.length > 0
        ? `${currentPostMessage} ${caption}`
        : caption;

    setValue('postMessage', newPostMessage, {
      shouldValidate: newPostMessage.length !== 0
    });
  };

  if (isSubmitting) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner animation="border" />
      </div>
    );
  }

  // dynamically resize textArea to fit text
  const resizeTextArea = (event: React.KeyboardEvent) => {
    const target = event.target as HTMLTextAreaElement;
    target.style.height = 'inherit';
    target.style.height = `${target.scrollHeight}px`;
  };

  return (
    <Form noValidate onSubmit={(event) => event.preventDefault()}>
      <div className="separator">
        <hr />
      </div>

      {!isGoingOnline && (
        <div className="form-info-box mb-4">
          <span>{infoBoxCopy}</span>
        </div>
      )}

      <Form.Group
        controlId="postMessage"
        className="position-relative mb-2"
        data-testid="postMessage"
      >
        <Form.Control
          as="textarea"
          className="postMessage"
          rows={isGoingOnline ? 3 : 1}
          name="postMessage"
          isInvalid={!!errors.postMessage?.message}
          {...register('postMessage')}
          {...(!isGoingOnline && {
            placeholder: 'Enter message here'
          })}
          onKeyUp={resizeTextArea}
          onKeyPress={resizeTextArea}
          maxLength={200}
        />

        <p
          className={
            errors.postMessage?.message
              ? 'help-text-invalid'
              : 'help-text-valid'
          }
        >
          Please use a minimum of 10 characters
        </p>
      </Form.Group>

      <div className="separator">
        <hr />
      </div>

      <Captions onChange={updatePostMessageCaption} />
    </Form>
  );
}
