import React, {
  ReactNode,
  useCallback,
  useEffect,
  useState,
  useMemo
} from 'react';
import Button, { ButtonProps } from 'react-bootstrap/Button';
import { ButtonVariant } from 'react-bootstrap/esm/types';
import { useSpring, animated } from 'react-spring';
import { useSwipeable } from 'react-swipeable';
import debounce from 'lodash.debounce';

interface ButtonSwiper extends ButtonProps {
  variant?: ButtonVariant;
  children: ReactNode;
  completeCallback: () => void;
  testId: string;
}

/**
 *
 * @param element
 * @returns number: element width, left and right padding subtracted
 */
// TOOD: maybe get rid of this function and just hard code the padding value given we're only using bootstrap lg buttons across the app?
const getWidth = (element: Element) => {
  if (element) {
    const rec = element.getBoundingClientRect();

    const paddingLeft = window
      .getComputedStyle(element, null)
      .getPropertyValue('padding-left');

    const paddingRight = window
      .getComputedStyle(element, null)
      .getPropertyValue('padding-right');

    return rec.width - (parseInt(paddingLeft, 10) + parseInt(paddingRight, 10));
  }
};

// circle position starts at right:0
const defaultCirclePostion = 0;

// min distance before swipe starts
const swipeDelta = 10;

export default function ButtonSwiper({
  variant,
  children,
  completeCallback,
  testId
}: ButtonSwiper) {
  const [swiping, setSwiping] = useState(false);
  const [fadeText, setFadeText] = useState(false);
  const [circlePosition, setCirclePosition] = useState(defaultCirclePostion);
  const [buttonWidth, setButtonWidth] = useState<number>();
  const [positionExtent, setPositionExtent] = useState<number>();
  const [triggerExtent, setTriggerExtent] = useState<number>();
  const [circleWidth, setCircleWidth] = useState<number>();
  const [callbackComplete, setCallbackComplete] = useState(false);

  // animate button circle (swipe left then reset)
  const circleStyles = useSpring({
    to: { right: swiping ? circlePosition : defaultCirclePostion },
    config: { mass: 0.1, tension: 0, friction: 0, velocity: 1 }
  });

  // animate button text
  const textStyles = useSpring({
    opacity: fadeText ? 0 : 1
  });

  const resetAnimation = useCallback(() => {
    setCirclePosition(defaultCirclePostion);
    setSwiping(false);
    setFadeText(false);
  }, []);

  const debouncedResetAnimation550 = debounce(resetAnimation, 550, {
    leading: false,
    trailing: true
  });

  const debouncedResetAnimation300 = debounce(resetAnimation, 300, {
    leading: false,
    trailing: true
  });

  const debouncedResetAnimation150 = debounce(resetAnimation, 150, {
    leading: false,
    trailing: true
  });

  const debouncedCompleteCallback = useMemo(
    () =>
      debounce(completeCallback, 250, {
        leading: false,
        trailing: true
      }),
    [completeCallback]
  );

  // reset animation on unmount
  useEffect(() => {
    return () => {
      resetAnimation();
    };
  }, [resetAnimation]);

  // Callbacks are triggered when the ref gets attached to the node
  const buttonRef = useCallback(
    (element: HTMLButtonElement) => {
      if (element !== null) {
        const btnWidth = getWidth(element);
        const posIntent = btnWidth - circleWidth / 2;
        setButtonWidth(btnWidth);
        setPositionExtent(posIntent);
        setTriggerExtent(posIntent / 2);
      }
    },
    [circleWidth]
  );

  const circleRef = useCallback((element: HTMLDivElement) => {
    if (element !== null) {
      setCircleWidth(element.offsetWidth);
    }
  }, []);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      // reset after left swipe is completed
      debouncedResetAnimation300();
      // set to false so callbacks can be called again
      setCallbackComplete(false);
    },
    onSwiping: (data) => {
      // reset if not swiping left
      if (data.dir.toLowerCase() !== 'left') {
        debouncedResetAnimation150();
        return;
      }

      setSwiping(true);

      // fade button text
      setFadeText(circlePosition > buttonWidth / 3);

      // get the minimum value - current swipe position or extent
      const finalPos = Math.min(data.absX, positionExtent);

      // update circle position
      setCirclePosition(finalPos);

      if (finalPos >= triggerExtent) {
        // force animation to extent
        setCirclePosition(positionExtent);
        // swipe is at extent - fire callback
        if (!callbackComplete) {
          debouncedCompleteCallback();
          // set to true to prevent any further calls
          setCallbackComplete(true);
        }
      }
    },
    delta: swipeDelta,
    trackMouse: true,
    preventDefaultTouchmoveEvent: true
  });

  const clickHandler = (event) => {
    event.preventDefault();

    if (!swiping) {
      setSwiping(true);
      // fade button text
      setFadeText(true);
      // force animation to extent
      setCirclePosition(positionExtent);
      // swipe is at extent - fire callback
      debouncedCompleteCallback();
      // reset after pausing
      debouncedResetAnimation550();
    }
  };

  return (
    <Button
      {...(variant && { variant: variant })}
      ref={buttonRef}
      onClick={(event) => event.preventDefault()}
      onMouseUp={clickHandler}
      onTouchEnd={clickHandler}
      data-testid={testId}
    >
      <animated.div
        className="swiper-circle-wrapper"
        {...handlers}
        ref={(element) => {
          handlers.ref(element);
          circleRef(element);
        }}
        style={circleStyles}
        onClick={(event) => event.preventDefault()}
      >
        <div className="slider-circle" data-testid="sliderCircle" />
      </animated.div>
      <animated.div style={textStyles}>
        <span>{children}</span>
      </animated.div>
    </Button>
  );
}
