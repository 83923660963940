import React, { useRef } from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from 'next-share';
import { CheckIcon, FacebookIcon, TwitterIcon, WhatsappIcon } from '../svg';
import { UseFormTrigger } from 'react-hook-form';
import { getEnvDomain } from '@src/utils';
import { GA_EVENTS, trackEvent } from '@src/services/gtag';

export default function SocialShare({
  postMessage,
  isValid,
  trigger,
  isGoingOnline
}: {
  postMessage: string;
  isValid: boolean;
  trigger: UseFormTrigger<{
    postMessage: string;
  }>;
  isGoingOnline: boolean;
}) {
  const hashtag = isGoingOnline ? 'online' : 'offline';
  const postUrl = `${getEnvDomain()}/${hashtag}`;

  // remove all possible emojis
  const removeEmojis = (postMessage: string) => {
    if (postMessage) {
      return postMessage.replace(new RegExp('[\u1000-\uFFFF]+', 'g'), '');
    }
    return null;
  };

  const platforms = [
    {
      name: 'facebook',
      buttonRef: useRef<HTMLButtonElement>(),
      Component: React.forwardRef<HTMLButtonElement>(function fB(
        _props,
        buttonRef
      ) {
        return (
          <FacebookShareButton
            url={postUrl}
            // Facebook does not support Emojis via share API so remove them
            quote={removeEmojis(postMessage)}
            hashtag={`#${hashtag}`}
            ref={buttonRef}
          >
            <FacebookIcon />
            <CheckIcon />
          </FacebookShareButton>
        );
      })
    },
    {
      name: 'twitter',
      buttonRef: useRef<HTMLButtonElement>(),
      Component: React.forwardRef<HTMLButtonElement>(function tW(
        _props,
        buttonRef
      ) {
        return (
          <TwitterShareButton
            url={postUrl}
            title={postMessage}
            hashtags={[hashtag]}
            ref={buttonRef}
          >
            <TwitterIcon />
            <CheckIcon />
          </TwitterShareButton>
        );
      })
    },
    {
      name: 'whatsapp',
      buttonRef: useRef<HTMLButtonElement>(),
      Component: React.forwardRef<HTMLButtonElement>(function wA(
        _props,
        buttonRef
      ) {
        return (
          <WhatsappShareButton
            url={postUrl}
            title={postMessage}
            separator=" "
            ref={buttonRef}
          >
            <WhatsappIcon />
            <CheckIcon />
          </WhatsappShareButton>
        );
      })
    }
  ];

  const clickShareButton = async (
    buttonRef: React.MutableRefObject<HTMLButtonElement>
  ) => {
    if (buttonRef.current) {
      trackEvent(GA_EVENTS.CLICK_SHARE_BUTTON);
      if (isValid) {
        // add clicked className to the button to display checkIcon - note: user can continue to click button
        buttonRef.current.className = 'icon-pressed';
        // click the button
        buttonRef.current.click();
      } else {
        // trigger form validation
        await trigger();
      }
    }
  };

  return (
    <div
      className="d-flex justify-content-between share-icons"
      data-testid="socialShareSection"
    >
      {platforms.map(({ name, buttonRef, Component }) => {
        return (
          <div
            key={name}
            onClick={() => clickShareButton(buttonRef)}
            className="d-inline-block flex-grow-1"
          >
            <Component ref={buttonRef} />
          </div>
        );
      })}
    </div>
  );
}
