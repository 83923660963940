import React, { MouseEventHandler, useState } from 'react';
import ReactDOM from 'react-dom';
import { useSpring, animated } from 'react-spring';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import CloseIcon from '../svg/CloseIcon';
import { useRecoilValue } from 'recoil';
import { modalGlobalState } from '@src/recoil';
import { ShareOnSocial, Faqs, Terms } from '@src/components/modal';

interface ModalPortal {
  showModal: boolean;
  closeModal: MouseEventHandler<HTMLElement>;
}

export default function ModalPortal({ showModal, closeModal }: ModalPortal) {
  const { modalContent, showImmediate, isGoingOnline } =
    useRecoilValue(modalGlobalState);

  const [shouldRender, setShouldRender] = useState(false);

  const modalTopPosition =
    typeof window !== 'undefined' ? window.innerHeight : 0;

  const modalAnimationStyles = useSpring({
    top: showModal ? 0 : modalTopPosition,
    immediate: showImmediate,
    onStart: () => {
      if (showModal) {
        setShouldRender(true);
      }
    },
    onRest: () => {
      if (!showModal) {
        setShouldRender(false);
      }
    }
  });

  const getTitle = (modalContent: modalGlobalState['modalContent']) => {
    let title = 'Share';

    switch (modalContent) {
      case 'faqs':
        title = 'FAQs';
        break;
      case 'terms':
        title = 'Terms and Conditions';
        break;
      default:
        title = 'Share';
    }

    return title;
  };

  if (typeof window !== 'undefined' && shouldRender) {
    return ReactDOM.createPortal(
      <animated.div
        style={modalAnimationStyles}
        className="modal-portal vh-100 d-flex flex-column px-0 container bg-white mb-7"
      >
        <Container className="my-2">
          <Nav as="ul" className="position-relative">
            <Nav.Item as="li">
              <Nav.Link className="position-absolute px-0" onClick={closeModal}>
                <CloseIcon />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" className="mx-auto">
              <Nav.Link disabled>
                <strong className="text-black">{getTitle(modalContent)}</strong>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Container>
        {modalContent === 'share' && (
          <ShareOnSocial
            closeModal={closeModal}
            isGoingOnline={isGoingOnline}
          />
        )}
        {modalContent === 'faqs' && <Faqs />}
        {modalContent === 'terms' && <Terms />}
      </animated.div>,
      document.getElementById('modal-portal')
    );
  }
  return null;
}
