import { TimeParts } from 'react-compound-timer/build/types';
import { CANCEL_TIMER_THRESHOLD, MAX_TIME_THRESHOLD } from '../constants';

/**
 *
 * @param time unix timestamp (ms)
 * @returns result of subtracted current unix timestamp from stored unix timestamp to get lapsed milliseconds
 */
export const getTimeLapsed = (time: number) => {
  return new Date().getTime() - time || 0;
};

export interface FormattedTimeValue {
  h: string | number;
  m: string | number;
  s: string | number;
}

/**
 *
 * @param timeParts
 * @returns timeParts with zeros prepended to single digits
 */
export const formatTimerValue = (timeParts: TimeParts): FormattedTimeValue => {
  const format = (n: number) => (n < 10 ? `0${n}` : n);

  return {
    h: format(timeParts.h),
    m: format(timeParts.m),
    s: format(timeParts.s)
  };
};

/**
 *
 * @param time - unix timestamp (ms) - fireBaseUser.lifeModeStartTime
 * @returns boolean result of lapsed time >= MAX_TIME_THRESHOLD
 */
export const isOverMaxTimeThreshold = (time: number) => {
  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const timeLapsed = getTimeLapsed(time);

  // use for testing (1 minute threshold)
  // return Math.floor(timeLapsed / msPerMinute) >= 1;
  return Math.floor(timeLapsed / msPerHour) >= MAX_TIME_THRESHOLD;
};

export const isOverTimeThreshold = (time: FormattedTimeValue) => {
  return Number(time.h) > 0 || Number(time.m) >= CANCEL_TIMER_THRESHOLD;
};
