import React from 'react';

interface Emoji {
  label: string;
  symbol: string;
}

export default function Emoji({ label, symbol }: Emoji) {
  return (
    <span
      role="img"
      aria-label={label ? label : ''}
      aria-hidden={label ? 'false' : 'true'}
    >
      {' '}
      {symbol}
    </span>
  );
}
