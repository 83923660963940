import React from 'react';
import Head from 'next/head';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AppVersion from '../AppVersion';

export default function Terms() {
  return (
    <Container className="d-flex flex-column p-4 overflow-auto">
      <Head>
        <title>2degrees Real Mode | Terms and Conditions</title>
      </Head>
      <Row className="mb-7">
        <Col data-testid="termsList">
          <ul style={{ listStyleType: 'number' }}>
            <li>
              These terms and conditions apply to the 2degrees Real Mode
              Competition (Competition) conducted by Two Degrees Mobile Limited,
              136 Fanshawe Street, Auckland 1010, New Zealand (2degrees).
            </li>
            <li>
              The Competition begins on 31 January 2022 and ends 31 December
              2022 (Competition Period).
            </li>
            <li>
              To be eligible for the Competition you must:
              <ul style={{ listStyleType: 'number' }}>
                <li>be a NZ resident;</li>
                <li>
                  be at least 18 years old or be accompanied by your legal
                  guardian;
                </li>
                <li>enter the Competition during the Competition Period;</li>
                <li>
                  and 4. not be an employee or contractor of 2degrees or its
                  related entities.
                </li>
              </ul>
            </li>
            <li>
              By entering the Competition, you agree that 2degrees may collect,
              hold and use your personal information in accordance with our
              Privacy Policy and to conduct the Competition.
            </li>
            <li>
              To enter this Competition, you must activate Real Mode on your
              device for a minimum of 15 minutes.
            </li>
            <li>
              You will receive one valid entry for every 15 consecutive minutes
              you are active in Real Mode. Maximum of 10 entries per day. You
              can still operate the Real Mode app after this period (i.e., 10 x
              15 minute sessions), but you will not be eligible for additional
              entries for that day.
            </li>
            <li>
              2degrees will, in its sole discretion, select winners based on
              valid entries to receive a gift voucher up to the value of $100.
              (Winner). 2degrees will determine the prizes once the Winners have
              been chosen. 2degrees accepts no responsibility or liability for
              any prize Winner&rsquo;s enjoyment of any prize or any other costs
              and expenses that may be incurred by the prize Winner relating to
              the prize or the Winner&rsquo;s enjoyment of that prize (including
              any ancillary costs &ndash; e.g. travel related costs) where
              relevant and unless otherwise specified in future negotiations
              with 2degrees.
            </li>
            <li>
              The prize is not negotiable, transferrable, or exchangeable, and
              cannot be taken as cash.
            </li>
            <li>
              The Winner will be sent the prize to the address provided in the
              entry form.
            </li>
            <li>
              Accepting the prize constitutes a Winners consent to be
              photographed, videoed and/or interviewed by 2degrees and for
              2degrees to use his or her name, city/town of residence,
              competition entry and/or photographs and interviews for
              Competition or publicity purposes without compensation. This
              includes on 2degrees website, direct and Social media channels.
            </li>
            <li>
              As a participant you consent to 2degrees using your personal
              information and performance of any media for future Competitional,
              marketing and publicity purposes without reference, payment, or
              other compensation to you.
            </li>
            <li>
              If, for any reason, 2degrees reasonably suspects that an
              individual has tampered with, intervened in an unauthorised or
              fraudulent manner, or for any other reason which may interfere or
              affect the administration, security, fairness, integrity or proper
              conduct of the Competition, 2degrees reserves the right, in its
              sole discretion, to disqualify any participant, take any action
              that may be available or to cancel, terminate, modify or suspend
              the Competition.
            </li>
            <li>
              2degrees&rsquo; decisions on all matters regarding the Competition
              are final and no correspondence will be entered into.
            </li>
            <li>
              2degrees reserves the right, acting reasonably, to extend, change,
              or terminate all or part of the Competition at any time without
              notice.
            </li>
            <li>
              2degrees assumes no responsibility for, and entrants release
              2degrees from, any loss, claim, damage, or injury to person or
              property as a result of the entrant&rsquo;s participation in the
              Competition, (or the conduct of this Competition generally),
              except for any liability that cannot be excluded by law.
            </li>
            <li>
              You are deemed to accept these terms and conditions at the time of
              entering into the Competition.
            </li>
          </ul>

          <AppVersion />
        </Col>
      </Row>
    </Container>
  );
}
