import { getEnvDomain } from '@src/utils';
import { useCallback, useEffect, useState } from 'react';

export const useWebShareApi = () => {
  // TODO: should this check be part of getEnvDomain()? I don't want to mess with it now though (time constraints)
  const domain =
    typeof window !== 'undefined' && window.location.hostname === 'localhost'
      ? 'http://localhost:3000'
      : getEnvDomain();

  const shareAPI: any = typeof window !== 'undefined' && window.navigator;

  /**
   * downloads a 1x1.png to test eb Share API support
   * @returns boolean
   */
  const checkFeatureSupport = useCallback(async () => {
    const response = await fetch(`${domain}/images/1x1.png`);
    const blob = await response.blob();
    const filesArray = [
      new File([blob], 'test.png', {
        type: 'image/png',
        lastModified: new Date().getTime()
      })
    ];

    return (
      shareAPI.share &&
      shareAPI.canShare &&
      shareAPI.canShare({
        files: filesArray
      })
    );
  }, [domain, shareAPI]);

  /**
   * share image via Web Share API
   * @param image
   * @param name
   */
  const shareImage = async (image: string, name: string) => {
    const response = await fetch(`${domain}/images/${image}`);
    const blob = await response.blob();
    const filesArray = [
      new File([blob], `${name}.png`, {
        type: 'image/png',
        lastModified: new Date().getTime()
      })
    ];

    shareAPI.share({
      files: filesArray
    });
  };

  // PWA on iOS is buggy hence this strange approach
  const downloadImage = (url: string, name: string) => {
    // https://developer.apple.com/forums/thread/95911
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.onload = function () {
      if (this.status == 200) {
        const myBlob = this.response;
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(myBlob);
        link.download = name;
        link.click();
        link.remove();
      }
    };
    xhr.send();
    // }
  };

  const [supportsWebShareApi, setSupportsWebShareApi] = useState(false);

  useEffect(() => {
    checkFeatureSupport().then((res) => {
      setSupportsWebShareApi(res || false);
    });
  }, [checkFeatureSupport]);

  return { supportsWebShareApi, shareImage, downloadImage };
};
