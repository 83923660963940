import React from 'react';

export default function CloseIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 20"
      width="20px"
      data-testid="closeIcon"
    >
      <path
        fill="#009ED8"
        d="M1.308 18.584c.494.483 1.302.483 1.774.011l6.907-6.906 6.906 6.906c.483.472 1.292.483 1.775-.011.483-.483.483-1.291.011-1.763l-6.907-6.918 6.907-6.907c.472-.472.483-1.291-.011-1.763a1.278 1.278 0 00-1.775-.011L9.99 8.128 3.082 1.222a1.278 1.278 0 00-1.774 0c-.483.494-.472 1.314 0 1.774l6.906 6.907-6.906 6.918c-.472.46-.483 1.291 0 1.763z"
      ></path>
    </svg>
  );
}
