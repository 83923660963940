import React from 'react';
import { RecoilRoot, useRecoilState } from 'recoil';
import Script from 'next/script';
import { FirebaseService } from '../services/firebase';
import useInitialiseApp from '@src/hooks/useInitialiseApp';
import AppLoader from '@src/components/AppLoader';
import AndroidPostInstallNotification from '@src/components/AndroidPostInstallNotification';
import '@src/styles/globals.scss';
import {
  modalGlobalState,
  downloadDialogueGlobalState,
  tipsDialogueGlobalState,
  confirmDialogueGlobalState
} from '@src/recoil';
import {
  ModalPortal,
  DownloadDialoguePortal,
  TipsDialoguePortal,
  ConfirmDialoguePortal
} from '@src/components/portal';
import { isProd } from '@src/utils/helpers';

if (typeof window !== 'undefined') {
  FirebaseService.getInstance(); // called for initializing firebase as early as possible (but only when we're in client-land)
}

function PortalRoot() {
  const [modalState, setModalState] = useRecoilState(modalGlobalState);
  const [tipsDialogueState] = useRecoilState(tipsDialogueGlobalState);
  const [downloadDialogueState] = useRecoilState(downloadDialogueGlobalState);
  const [confirmDialogueState] = useRecoilState(confirmDialogueGlobalState);

  // injects portals into portal roots
  return (
    <>
      {isProd() && (
        <Script
          // this should run immediately after the page becomes interactive and I doubt it won't finish by the time it's needed (when clicking a button etc).
          // But if it does cause issues we can change it to a standard inline <script> tag that will execute before the page is interactive (will probably introduce latency though)
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
          if (typeof gtag === 'undefined' || !gtag) {
            f = document.getElementsByTagName('script')[0];
            j = document.createElement('script');
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtag/js?id=DC-11031121';
            f.parentNode.insertBefore(j,f);
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'DC-11031121');
          }
      `
          }}
          id="gtagLoader" // not documented, but looking at the Next source code it looks like it's used as a cache key when the src prop is not passed
        />
      )}
      <ModalPortal
        showModal={modalState.open}
        closeModal={() =>
          setModalState({
            modalContent: modalState.modalContent,
            open: false
          })
        }
      />
      <DownloadDialoguePortal showDialogue={downloadDialogueState.open} />
      <TipsDialoguePortal showDialogue={tipsDialogueState.open} />
      <ConfirmDialoguePortal showDialogue={confirmDialogueState.open} />
    </>
  );
}

function App({ Component, pageProps }) {
  const isLoading = useInitialiseApp();

  return (
    <RecoilRoot>
      <PortalRoot />
      <AppLoader isLoading={isLoading}>
        <AndroidPostInstallNotification />
        <Component {...pageProps} />
      </AppLoader>
    </RecoilRoot>
  );
}

export default App;
