import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { useSpring, animated } from 'react-spring';
import { useRouter } from 'next/router';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { FirebaseService } from '@src/services/firebase';
import { ROUTES } from '@src/types/routes';
import { userDetailsPreviouslyCaptured, isOverTimeThreshold } from '@src/utils';
import { confirmDialogueGlobalState } from '@src/recoil';
import { useRecoilState } from 'recoil';
import useFirebaseUser from '@src/hooks/useFirebaseUser';

const confirmationCopy = [
  {
    question:
      'Just checking, do you really want to swap socialising for Social already?',
    cancelButtonText: 'No way, Jose!',
    confirmButtonText: 'Sorry but yes'
  },
  {
    question:
      'Sorry to be annoying... but remember you do go into the prize draw for every 15 minutes you stay... still leaving?',
    cancelButtonText: 'No',
    confirmButtonText: 'Yes'
  }
];

export default function ConfirmDialoguePortal({ showDialogue }) {
  const [user] = useFirebaseUser();
  const [confirmDialogueState, setConfirmDialogueState] = useRecoilState(
    confirmDialogueGlobalState
  );
  const router = useRouter();
  const [shouldRender, setShouldRender] = useState(false);

  // aninmate dialogue (slide up/down)
  const confirmDialogueAnimationStyles = useSpring({
    bottom: showDialogue ? 0 : -300,
    onStart: () => {
      if (showDialogue) {
        setShouldRender(true);
      }
    },

    onRest: () => {
      if (!showDialogue) {
        setShouldRender(false);
      }
    }
  });

  // dismissing dialogue resets dialogue state
  const dismissDialogue = () => {
    setConfirmDialogueState({
      ...confirmDialogueState,
      open: false,
      confirmCount: 0
    });
  };

  // cycle through dialogs until final one where LifeMode is deactivated
  const cycleDialog = () => {
    const maxCycles = confirmationCopy.length - 1;

    const hasCycled = window.localStorage.getItem('CONFIRM_CYCLED') === 'true';

    // if user has been through the confirmation cycle (2 dialogues) once before
    if (hasCycled) {
      dismissDialogue();
      FirebaseService.deactivateLifeMode();

      // navigate to `/canceled` route if under 15min
      if (!isOverTimeThreshold(confirmDialogueState.timeSpent)) {
        router.replace({
          pathname: ROUTES.CANCELED
        });
      }
      // navigate to `/enter-draw` route if over 15min
      else {
        router.replace({
          pathname: ROUTES.ENTER_DRAW,
          query: {
            timeSpent: decodeURIComponent(
              JSON.stringify(confirmDialogueState.timeSpent)
            ),
            userDetailsPreviouslyCaptured: userDetailsPreviouslyCaptured(user)
          }
        });
      }
    }
    // if user has NOT been through the confirmation cycle (2 dialogues) once before
    else {
      // increment confirmCount for next cycle
      if (confirmDialogueState.confirmCount < maxCycles) {
        setConfirmDialogueState({
          ...confirmDialogueState,
          confirmCount: confirmDialogueState.confirmCount + 1
        });
      }
      // max cycles reached, navigate to `/canceled` route
      else {
        // if user has been through the confirmation cycle once, we don't want to cycle them again so persist in LocalStorage
        window.localStorage.setItem('CONFIRM_CYCLED', 'true');
        dismissDialogue();
        FirebaseService.deactivateLifeMode();

        router.replace({
          pathname: ROUTES.CANCELED
        });
      }
    }
  };

  // get the appropriate copy
  const { question, cancelButtonText, confirmButtonText } =
    confirmationCopy[confirmDialogueState.confirmCount];

  if (typeof window !== 'undefined' && shouldRender) {
    return ReactDOM.createPortal(
      <Container className="dialogue-container text-white p-0">
        <animated.div
          className="dialogue-container-inner bg-primary px-4 text-start"
          style={confirmDialogueAnimationStyles}
        >
          <div className="d-grid gap-3 mb-4 mt-auto">
            <p className="my-2">{question}</p>
            <Button
              data-testid={`cancelButton${
                confirmDialogueState.confirmCount + 1
              }`}
              variant="light"
              size="lg"
              type="submit"
              onClick={dismissDialogue}
            >
              {cancelButtonText}
            </Button>
            <Button
              data-testid={`confirmButton${
                confirmDialogueState.confirmCount + 1
              }`}
              variant="outline-secondary"
              size="lg"
              type="submit"
              onClick={cycleDialog}
            >
              {confirmButtonText}
            </Button>
          </div>
        </animated.div>
      </Container>,
      document.getElementById('confirm-dialogue-portal')
    );
  }
  return null;
}
