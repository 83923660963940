import React from 'react';

export default function CheckIcon({ fill = '#fff' }) {
  return (
    <svg viewBox="-14 -13 49 48" height="48" width="48" fill="none">
      <path
        fill={fill}
        d="M9.095 20.133c.852 0 1.496-.324 1.934-.973L21.306 3.562c.164-.242.281-.476.351-.703.07-.234.106-.457.106-.668 0-.578-.2-1.058-.598-1.441-.39-.39-.886-.586-1.488-.586-.406 0-.75.082-1.031.246-.281.164-.551.442-.809.832l-8.789 13.84-4.43-5.39c-.437-.516-.984-.774-1.64-.774-.602 0-1.102.195-1.5.586-.39.383-.586.867-.586 1.453 0 .266.043.516.129.75.094.234.254.48.48.738l5.707 6.82c.5.579 1.13.868 1.887.868z"
      ></path>
    </svg>
  );
}
