import React from 'react';
import Head from 'next/head';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { modalGlobalState } from '@src/recoil';
import { useRecoilState } from 'recoil';
import AppVersion from '../AppVersion';

export default function Faqs() {
  const [, setModalState] = useRecoilState(modalGlobalState);

  const termsLink = () => (
    <a
      className="text-center my-4"
      data-testid="termsLink"
      onClick={(event) => {
        event.preventDefault();
        setModalState({ modalContent: 'terms', open: true });
      }}
    >
      Full terms and conditions can be found here.
    </a>
  );

  return (
    <Container className="d-flex flex-column p-4 overflow-auto">
      <Head>
        <title>2degrees Real Mode | Privacy Policy</title>
      </Head>
      <Row className="mb-7">
        <Col data-testid="FAQmodal">
          <p>
            <strong>What does Real Mode do?</strong>
          </p>
          <p>
            <span>
              Real Mode has been designed to help you step away from digital
              distractions for a while and let you concentrate on getting social
              in the real world. It works by interrupting your usual habits and
              making sure you are mindfully scrolling. You can choose to push
              out notifications to your Social channels to let your mates know
              you are out for a while. And every 15 minutes completed in Real
              Mode gives you a chance to win great experiences that can be
              shared in the real world.
            </span>
          </p>
          <p>
            <strong>How do I install the app in IOS using Chrome? </strong>
          </p>
          <p>
            <span>
              Unfortunately, in IOS, this process only works from the Safari
              browser. If you have Chrome or any alternative set as your default
              browser please open the link in Safari and install from there.
            </span>
          </p>
          <p>
            <strong>
              What happens if I&rsquo;m in Real Mode and I need to use my phone
              for something important?
            </strong>
          </p>
          <p>
            <span>
              You can switch out of Real Mode at any time &ndash; you are only
              ever a few taps away from using your phone as you normally would.
              Just follow the easy on-screen prompts.
            </span>
          </p>
          <p>
            <strong>Will Real Mode stop me receiving calls or texts?</strong>
          </p>
          <p>
            <span>
              No, Real Mode doesn&rsquo;t disable any of the functions of your
              device. It&rsquo;s here to help you help yourself, not to stop you
              being contacted. We will simply remind you when you go back to
              your phone, that you had chosen to step away for a while. We do
              recommend putting your phone on silent, or turning it to Do Not
              Disturb mode though, if you don&rsquo;t want your time in Real
              Mode to be interrupted.
            </span>
          </p>
          <p>
            <strong>
              Will Real Mode stop me getting notifications from my Social
              channels?
            </strong>
          </p>
          <p>
            <span>
              No, Real Mode doesn&rsquo;t disable any of the functions of your
              device. Rather, it helps you make a conscious decision to step
              away. When you&rsquo;re in Real Mode, it&rsquo;s a good idea to
              keep your phone out of sight to avoid any distracting pop-ups.
            </span>
          </p>
          <p>
            <strong>
              Do I need to be a 2degrees customer to use Real Mode?
            </strong>
          </p>
          <p>
            <span>
              Nope, we want to help all of New Zealand to enjoy some time
              connecting face to face again. Anyone who wants to use Real Mode
              can. It’s only fair.
            </span>
          </p>
          <p>
            <strong>Does Real Mode post to my Socials?</strong>
          </p>
          <p>
            <span>
              Only if you want it to, and only if you tell us to. You can use
              Real Mode to post an &lsquo;out of office&rsquo; style message on
              your favourite Social channels. Real Mode will always ask for your
              permission to access those apps.
            </span>
          </p>
          <p>
            <strong>What prizes can I win?</strong>
          </p>
          <p>
            <span>
              For every full 15 minutes you spend in Real Mode, you will get an
              entry into a draw to score a bunch of great experiences (capped at
              10 entries a day). It could be a meal, a movie or much, much more!
              But remember, the real prize is spending time together in the real
              world. {termsLink()}
            </span>
          </p>
          <p>
            <strong>How often can I use Real Mode? </strong>
          </p>
          <p>
            <span>
              There is absolutely no limit to how often you enter Real Mode. Use
              it morning, noon and night. Go on, see how long you can go. We
              dare you! {termsLink()}
            </span>
          </p>

          <AppVersion />
        </Col>
      </Row>
    </Container>
  );
}
