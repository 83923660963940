import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { ROUTES } from '@src/types/routes';
import {
  userDetailsPreviouslyCaptured,
  isOverMaxTimeThreshold,
  isPwaInstalled
} from '@src/utils';
import useFirebaseUser from './useFirebaseUser';
import { GA_EVENTS, trackEvent } from '@src/services/gtag';

export default function useInitialiseApp() {
  const [user, loading, error] = useFirebaseUser();
  const [hasInitialised, setHasInitialised] = useState(false);
  const router = useRouter();

  useEffect(() => {
    if (!hasInitialised) {
      // GA event
      trackEvent(GA_EVENTS.APP_LOAD);
    }

    // guard against infinite loop
    if (!loading && !hasInitialised) {
      if (user?.lifeModeActive) {
        // if lifeMode is active AND over max allowed time threshold navigate to `/enter-draw`
        if (isOverMaxTimeThreshold(user.lifeModeStartTime)) {
          const hasUserDetails = userDetailsPreviouslyCaptured(user);
          router.replace({
            pathname: ROUTES.ENTER_DRAW,
            query: {
              // if user details have been previously captured setting `userDetailsPreviouslyCaptured` to true
              // will put the form in a success state
              userDetailsPreviouslyCaptured: hasUserDetails
            }
          });
        } else {
          // redirect to `/active` if lifeMode is active
          router.replace({
            pathname: ROUTES.ACTIVE
          });
        }
      } else {
        // navigate user with PWA installed to '/welcome'
        if (isPwaInstalled()) {
          router.replace({
            pathname: ROUTES.WELCOME
          });
        }
      }
      setHasInitialised(true);
    }
  }, [hasInitialised, router, user, loading, error]);

  return loading;
}
