import React, { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { ROUTES } from '@src/types/routes';
import LayoutVertical from './LayoutVertical';
import useWindowUtils from '@src/hooks/useWindowUtils';
import {
  confirmDialogueGlobalState,
  downloadDialogueGlobalState,
  modalGlobalState,
  tipsDialogueGlobalState
} from '@src/recoil';
import { useResetRecoilState } from 'recoil';

export default function AppLoader({ isLoading, children }) {
  const router = useRouter();
  const { isDesktop } = useWindowUtils();

  const resetModal = useResetRecoilState(modalGlobalState);
  const resetTipsDialogue = useResetRecoilState(tipsDialogueGlobalState);
  const resetDownloadDialogue = useResetRecoilState(
    downloadDialogueGlobalState
  );
  const resetConfirmDialogue = useResetRecoilState(confirmDialogueGlobalState);

  // force to desktop route
  if (isDesktop === true) {
    if (!router.pathname?.includes(ROUTES.DESKTOP)) {
      router.replace({
        pathname: ROUTES.DESKTOP
      });
    }
  }

  // close modals and dialogues on route change
  const handleRouteChange = useCallback(() => {
    resetModal();
    resetTipsDialogue();
    resetDownloadDialogue();
    resetConfirmDialogue();
  }, [
    resetConfirmDialogue,
    resetDownloadDialogue,
    resetModal,
    resetTipsDialogue
  ]);

  // listen for routeChangeStart events
  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [handleRouteChange, router.events]);

  const renderComponent = () => {
    // return null for desktop
    return isDesktop === true ? null : <LayoutVertical />;
  };

  return !isLoading ? children : renderComponent();
}
