import { DocumentData } from 'firebase/firestore';
import { FirebaseService } from '@src/services/firebase';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';

export default function useFirebaseUser() {
  // hacking a limitation of useAuthState - the library's pattern of passing null when wanting to disable the subscription (e.g. when in server side),
  // gets a build error in this case, due to useAuthState code trying to read auth.currentUser when auth is null. As a workaround we are passing it an object with currentUser = undefined
  const [authUser, authLoading, authError] = useAuthState(
    typeof window === 'undefined'
      ? ({ currentUser: undefined } as any)
      : FirebaseService.getInstance().auth
  );
  const [userData, userDataLoading, userDataError] =
    useDocumentData<DocumentData>(
      authUser?.uid ? FirebaseService.getUserRef(authUser.uid) : null
    );

  // if there are no errors, but also no result, we treat it as loading since in practice it means the necessary refs for the subscriptions to be active, are not ready yet
  // Shouldn't happen really, but does seem to, since looking at the loading values returned by the hooks alone misses state updates
  const loading =
    authLoading ||
    userDataLoading ||
    (!userData && !authError && !userDataError);

  if (authError) {
    console.log('authError', authError);
  }
  if (userDataError) {
    console.log('userDataError', userDataError);
  }

  return [userData as any, loading, userDataError];
}
