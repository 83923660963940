/**
 *
 * @param user
 * @returns boolean representation of previously captured user details (i.e. user has previously completed enter-draw form)
 */
export const userDetailsPreviouslyCaptured = (user: {
  name: string;
  address?: string;
  phone?: string;
}) => {
  return !!user.name && (!!user.address || !!user.phone);
};
