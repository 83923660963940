import { atom } from 'recoil';
import { FormattedTimeValue } from '@src/utils/timer';

export interface modalGlobalState {
  modalContent?: 'share' | 'faqs' | 'terms' | 'privacy-policy' | null;
  open: boolean;
  showImmediate?: boolean;
  isGoingOnline?: boolean;
  preSelectedVideoId?: string;
}

export const modalGlobalState = atom<modalGlobalState>({
  // unique ID (with respect to other atoms/selectors)
  key: 'modal',
  // default value (aka initial value)
  default: {
    modalContent: null, // set to null to prevent child component mounting
    open: false,
    showImmediate: false,
    isGoingOnline: false,
    preSelectedVideoId: null
  }
});

interface confirmDialogueGlobalState {
  open?: boolean;
  confirmCount?: number;
  timeSpent?: FormattedTimeValue;
}

export const confirmDialogueGlobalState = atom<confirmDialogueGlobalState>({
  key: 'confirm',
  default: {
    open: false,
    confirmCount: 0,
    timeSpent: { h: '00', m: '00', s: '00' }
  }
});

interface tipsDialogueGlobalState {
  open?: boolean;
}

export const tipsDialogueGlobalState = atom<tipsDialogueGlobalState>({
  key: 'tips',
  default: {
    open: false
  }
});

interface downloadDialogueGlobalState {
  open?: boolean;
}
export const downloadDialogueGlobalState = atom<downloadDialogueGlobalState>({
  key: 'download',
  default: {
    open: false
  }
});
