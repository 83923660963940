import { DESKTOP_MIN_WIDTH } from '../constants';

/**
 * NOTE: The below functions [isPlatformiOS, isPwaInstalled and isDesktop] should be used only from a post first app mount context (e.g. useEffect)
 * The best way is to use our custom hook useWindowUtils which wraps the below methods but guarantees they return reliable 'UNINITIALIZED' during build time
 * The UI should then base any related conditional logic on explicit checks like isPlatformiOS === true / isPlatformiOS === false, to ensure the rendered UI is consistent
 * between first client side rendering (rehydration) and SSG rendering
 * See: https://www.joshwcomeau.com/react/the-perils-of-rehydration/
 */

/**
 *
 * @returns boolean - platform is iOS
 */
export const isPlatformiOS = () => {
  if (typeof window !== 'undefined') {
    return (
      [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  }
};

/**
 *
 * @returns boolean - PWA is installed
 */
export const isPwaInstalled = () => {
  if (typeof window !== 'undefined') {
    return window.matchMedia('(display-mode: standalone)').matches;
  }
};

/**
 *
 * @returns boolean - assume user is on Desktop based on screen width
 */
export const isDesktop = () => {
  if (typeof window !== 'undefined') {
    return window.screen.width > DESKTOP_MIN_WIDTH;
  }
};

/**
 *
 * @returns correct domain based on .env
 */
export const getEnvDomain = () => {
  let domain = 'realmode.co.nz';

  if (process.env.NEXT_PUBLIC_ENV === 'dev') {
    domain = 'realmode-dev.co.nz';
  }

  if (process.env.NEXT_PUBLIC_ENV === 'uat') {
    domain = 'realmode-uat.co.nz';
  }

  return `https://${domain}`;
};

// TODO - temporarily treating UAT as prod to allow client to test tracking. Change on go live!
export const isProd = () => {
  return (
    process.env.NEXT_PUBLIC_ENV === 'prod' ||
    process.env.ENV === 'prod' ||
    process.env.NEXT_PUBLIC_ENV === 'uat' ||
    process.env.ENV === 'uat'
  );
};
