import React from 'react';

export default function FacebookIcon() {
  return (
    <svg fill="none" viewBox="0 0 49 48" width="48" height="48">
      <path
        fill="#fff"
        d="M36.288 10.56h-23.91a1.485 1.485 0 00-1.485 1.485v23.91a1.485 1.485 0 001.486 1.485h12.87V27.033h-3.502v-4.06h3.501v-2.988c0-3.473 2.123-5.365 5.218-5.365 1.047 0 2.093.052 3.134.158v3.631h-2.15c-1.695 0-2.01.802-2.01 1.977v2.586h4.02l-.525 4.06h-3.496V37.44h6.85a1.485 1.485 0 001.485-1.485v-23.91a1.485 1.485 0 00-1.485-1.485z"
      ></path>
    </svg>
  );
}
