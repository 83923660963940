export enum GA_EVENTS {
  APP_LOAD = '7005r0',
  SWITCH_TO_REAL_MODE = '7000r0',
  SHARE_THEN_SWITCH = '7001r0',
  CLICK_SHARE_BUTTON = '7004r0',
  CLICK_SUBMIT_DETAILS = '7002r0',
  CLICK_MAYBE_NEXT_TIME = '7000r00'
}

export const trackEvent = (eventId: string): void => {
  try {
    if (typeof window !== 'undefined' && (window as any).gtag) {
      (window as any).gtag('event', 'conversion', {
        allow_custom_scripts: true,
        send_to: `DC-11031121/rmd000/${eventId}+standard`
      });
    }
  } catch (error) {
    console.log(error);
  }
};
