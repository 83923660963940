import React, { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import Alert from 'react-bootstrap/Alert';
import useWindowUtils from '@src/hooks/useWindowUtils';

export default function AndroidPostInstallNotification() {
  const [showNotification, setShowNotification] = useState(false);
  const { isPlatformAndroid } = useWindowUtils();

  // animate notification (slide in from right)
  const animationStyles = useSpring({
    right: showNotification ? 0 : -500,
    display: showNotification ? 'block' : 'none'
  });

  const postInstalledHandler = () => {
    // give Android some time to set up the App on the home page
    setTimeout(() => {
      setShowNotification(true);
    }, 5000);
  };

  useEffect(() => {
    if (isPlatformAndroid) {
      window.addEventListener('appinstalled', postInstalledHandler);

      return () => {
        window.removeEventListener('appinstalled', postInstalledHandler);
      };
    }
  }, [isPlatformAndroid]);

  // only display post install notification on Android - iOS handles this by default
  if (isPlatformAndroid === true) {
    return (
      <div className="position-relative w-100">
        <animated.div
          style={animationStyles}
          className="post-install-notification position-fixed w-100"
        >
          <Alert
            variant="secondary"
            className="helper m-2"
            onClick={() => {
              setShowNotification(!showNotification);
            }}
          >
            <a
              href={`${document.location.origin}/`}
              target="_blank"
              rel="noreferrer"
            >
              Go to App
            </a>
          </Alert>
        </animated.div>
      </div>
    );
  }

  return null;
}
