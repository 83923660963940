import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ErrorBoundary from './ErrorBoundary';
import Logo from './svg/Logo';

export default function LayoutVertical({
  children
}: {
  children?: React.ReactNode;
}) {
  return (
    <ErrorBoundary>
      <div dangerouslySetInnerHTML={{ __html: '<!-- Layout vertical -->' }} />
      <Container className="min-vh-100 d-flex flex-column justify-content-between px-6">
        <Row className="my-auto py-0 text-white">
          <Col className="d-flex justify-content-center align-items-center flex-column py-0">
            <Logo width="45vw" />
          </Col>
        </Row>
        {children && (
          <div className="d-flex flex-column justify-content-end">
            {children}
          </div>
        )}
      </Container>
    </ErrorBoundary>
  );
}
